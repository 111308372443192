//TODO: Currently used only for mcq checkpoints. If this is duel use for actual mcq challenges, then change name
//Otherwise, create seperate systems

//React
import { useState, useEffect } from 'react';

//UI
import { Button } from "flowbite-react";

//Services
import crudService from 'services/crudService';

//Logics
import shuffleArray from "logic/utility/shuffleArray";
import { useWindowDimensions } from "logic/hooks/useWindowDimensions";

//Components
import RenderPageSnippet from "views/render/RenderContentSnippet";

//Store
import { useAtom } from 'jotai';
import { loadedMcqs } from 'atom';

//Classes
//import { FilterModel } from "classes/models/request/FilterModel";
import { Class } from 'classes/enums/Class';
import { Mcq } from 'classes/synapp/mcq/Mcq';
import { McqSubmittedAnswer } from "classes/synapp/mcq/McqSubmittedAnswer";

type Props = {
    categoryIds: string[];
    completeCheckpoint: Function;
    parentType: string;
    parentId: string;
}

const McqChallengeSystem = (props: Props) => {

    const { viewHeight, viewWidth, viewBreakpoint } = useWindowDimensions();
    const { categoryIds, completeCheckpoint, parentType, parentId } = props;
    const [mcqs] = useAtom(loadedMcqs);
    const [filteredMcqsByCategory, setFilteredMcqsByCategory] = useState<Mcq[]>([]);
    const [filteredMcqs, setFilteredMcqs] = useState<Mcq[]>([]);
    const [selectedMcqIndex, setSelectedMcqIndex] = useState(-1);
    const [challengeStatus, setChallengeStatus] = useState("waiting");
    const [submittedAnswers, setSubmittedAnswers] = useState<McqSubmittedAnswer[]>([]);
    const [overViewCardSelected, setOverViewCardSelected] = useState(true);
    const [challengeCompletedSuccessfully, setChallengeCompletedSuccessfully] = useState(false);
    const [totalMcqsQuestionsInChallenge, setTotalMcqsQuestionsInChallenge] = useState(0);
    const [totalPointsAwarded, setTotalPointsAwarded] = useState(0);

    let numberOfMcqsToDisplay = 3;
    let passRate = 65;

    useEffect(() => {
        if (mcqs && mcqs.length > 0) {
            let parentId = categoryIds.length > 0 ? categoryIds[categoryIds.length - 1] : "None";
            let filtered = mcqs.filter(x => x.categoryIds.includes(parentId));
            if (filtered.length === 0 || filtered.length < numberOfMcqsToDisplay) {
                console.log("Filtered MCQS: ", filtered);
                console.log(mcqs);
                console.log(parentId);
                setChallengeStatus("error");
            } else {
                setChallengeStatus("waiting");
                setFilteredMcqsByCategory(filtered);
                selectChallengeMcqs(filtered);
            }
        }
    }, [mcqs.length]);

    const selectChallengeMcqs = (filteredMcqsByCategory: Mcq[]) => {
        let tempMcqs = [...filteredMcqsByCategory];
        setTotalMcqsQuestionsInChallenge(numberOfMcqsToDisplay);
        let selectedMcqs: Mcq[] = [];
        for (let i = 0; i < numberOfMcqsToDisplay; i++) {
            let randomIndex = Math.floor(Math.random() * tempMcqs.length);
            selectedMcqs.push(tempMcqs[randomIndex]);
            tempMcqs.splice(randomIndex, 1);
        }

        //Randomise the order of the answers
        selectedMcqs.forEach(mcq => {
            let tempAnswers = [...mcq.answers];
            let randomisedAnswers = shuffleArray(tempAnswers);
            mcq.answers = randomisedAnswers;
        });

        setFilteredMcqs(selectedMcqs);
    }

    const selectAnswer = (mcqId: string, answerLocalId: string) => {
        if (challengeStatus !== "running" || isAlreadyAnswered(mcqId)) {
            return;
        }

        let currentMcqId = filteredMcqs[selectedMcqIndex].id;
        let submittedAnswer = new McqSubmittedAnswer();
        submittedAnswer.mcqId = currentMcqId;
        submittedAnswer.submittedAnswerId = answerLocalId;

        let tempAnswers = [...submittedAnswers];
        //if answer already exists, replace it, otherwise add it
        let index = tempAnswers.findIndex(x => x.mcqId === currentMcqId);
        if (index === -1) {
            tempAnswers.push(submittedAnswer);
        } else {
            tempAnswers[index] = submittedAnswer;
        }
        setSubmittedAnswers(tempAnswers);
    }

    const sendAnswersToServer = async (tempSubmittedAnswers: McqSubmittedAnswer[]) => {

        tempSubmittedAnswers.forEach(answer => {
            answer.parentId = parentId;
            answer.parentType = parentType;

            //Assuming local grading for now
            //answer.pointsAwarded = answer.isCorrect ? 1 : 0;
        });

        let response = await crudService.create(Class.mcqSubmittedAnswer, tempSubmittedAnswers);
        if (response.success) {
            console.log("Answers submitted successfully!", response.payload);
        }
        else {
            console.log("Error submitting answers!", response);
        }
    }

    const gradeAnswers = () => {

        let tempSubmittedAnswers = [...submittedAnswers];
        filteredMcqs.forEach(mcq => {
            let correctAnswer = mcq.answers.find(x => x.isCorrect);
            let index = tempSubmittedAnswers.findIndex(x => x.mcqId === mcq.id);
            if (index === -1 || !correctAnswer) {
                console.error("No answer selected or found for this question. Cannot grade!")
                return;
            }
            if (tempSubmittedAnswers[index].submittedAnswerId === correctAnswer.id) {
                tempSubmittedAnswers[index].isCorrect = true;
                tempSubmittedAnswers[index].isGraded = true;
                tempSubmittedAnswers[index].pointsAwarded = 1 * mcq.difficulty;
            } else {
                tempSubmittedAnswers[index].isCorrect = false;
                tempSubmittedAnswers[index].isGraded = true;
            }
        });

        //Calculate total points awarded
        let totalPoints = 0;
        tempSubmittedAnswers.forEach(answer => {
            totalPoints += answer.pointsAwarded;
        });
        setTotalPointsAwarded(totalPoints);

        //Send answers to server
        sendAnswersToServer(tempSubmittedAnswers);

        setSubmittedAnswers(tempSubmittedAnswers);
        completeChallenge(tempSubmittedAnswers);
    }

    const completeChallenge = (submittedAnswers: McqSubmittedAnswer[]) => {
        //Check if pass rate is met
        let numberOfCorrectAnswers = submittedAnswers.filter(x => x.isCorrect).length;
        let percentageCorrect = (numberOfCorrectAnswers / totalMcqsQuestionsInChallenge) * 100;
        if (percentageCorrect >= passRate) {
            if (!challengeCompletedSuccessfully) {
                setChallengeCompletedSuccessfully(true);
                setSelectedMcqIndex(-1);
                setOverViewCardSelected(true);
            }

        } else {
            //console.log("Challenge failed!");
        }
        setChallengeStatus("completed");
    }

    const isAlreadyAnswered = (mcqId: string) => {
        let index = submittedAnswers.findIndex(x => x.mcqId === mcqId);
        if (index !== -1 && submittedAnswers[index].isGraded) {
            return true;
        } else {
            return false;
        }
    }

    const resetMcqChallenge = () => {
        setSubmittedAnswers([]);
        selectChallengeMcqs(filteredMcqsByCategory);
        setChallengeStatus("running");
        setSelectedMcqIndex(0);
    }

    //======================================================== Render and Render helper functions

    const renderChosenAnswerTick = (elementId: string) => {
        let currentMcqId = filteredMcqs[selectedMcqIndex].id;
        let index = submittedAnswers.findIndex(x => x.mcqId === currentMcqId);
        //If user has chosen an answer for this mcq
        if (index !== -1 && elementId === submittedAnswers[index].submittedAnswerId) {
            //return yellow if ungraded, green if correct, red if incorrect
            if (submittedAnswers[index].isGraded) {
                if (submittedAnswers[index].isCorrect) {
                    return "bg-green-500";
                } else {
                    return "bg-red-500";
                }
            } else {
                return "bg-yellow-500";
            }

        } else {
            return "";
        }
    }

    const allAnswersSubmitted = () => {
        if (challengeStatus !== "running") {
            return true;
        }
        let allAnswered = true;
        filteredMcqs.forEach(mcq => {
            let index = submittedAnswers.findIndex(x => x.mcqId === mcq.id);
            if (index === -1) {
                allAnswered = false;
            }
        });
        return allAnswered;
    }

    const renderOverviewCard = () => {

        if (challengeStatus === "waiting") {
            return (
                <div className="mx-auto">
                    <div className="font-bold text-md md:text-xl mb-4 text-center">MCQ Checkpoint</div>
                    <div className="flex gap-4 md:gap-6">
                        <div>
                            <div>Total Questions: {totalMcqsQuestionsInChallenge}</div>
                            <div>Pass rate: {passRate}%</div>
                        </div>
                        <Button onClick={() => { setChallengeStatus("running"); setSelectedMcqIndex(0); }}>Start!</Button>
                    </div>
                </div>
            )
        }
        else if (challengeStatus === "completed") {
            let correctAnswers = submittedAnswers.filter(x => x.isCorrect).length;
            let incorrectAnswers = submittedAnswers.filter(x => !x.isCorrect).length;
            let totalAnswers = correctAnswers + incorrectAnswers;

            return (
                <div>
                    <p>Challenge complete!</p>
                    <p>{correctAnswers + " / " + totalAnswers + " = " + (Math.floor((correctAnswers / totalAnswers) * 100)) + "%"}</p>
                    {challengeCompletedSuccessfully && <div>
                        <p>Challenge completed successfully!</p>
                        <Button onClick={() => completeCheckpoint(totalPointsAwarded)}>Complete Checkpoint</Button>
                    </div>}
                    {!challengeCompletedSuccessfully && <p>Challenge failed!</p>}
                    <Button onClick={() => resetMcqChallenge()}>Retry</Button>
                </div>
            )
        }
    }

    const getSelectionCardClassString = (index: number) => {
        let classString = "border-2 select-none text-xs p-3 m-3 w-20 h-16 ";
        classString += (selectedMcqIndex === index) ? " border-black" : " border-gray-300";
        let thisAnswer = submittedAnswers.find(x => x.mcqId === filteredMcqs[index].id);
        if (!thisAnswer) {
            classString += " bg-gray-100";
        }
        else if (thisAnswer.isGraded) {
            if (thisAnswer.isCorrect) {
                classString += " bg-green-200";
            } else {
                classString += " bg-red-200";
            }
        } else {
            classString += " bg-yellow-200";
        }
        return classString;
    }

    const getMcqBoxWidth = () => {
        if (viewBreakpoint === "sm") {
            return "100%";
        } else if (viewBreakpoint === "md") {
            return "500px";
        } else if (viewBreakpoint === "lg") {
            return "600px";
        } else if (viewBreakpoint === "xl") {
            return "700px";
        } else if (viewBreakpoint === "2xl") {
            return "800px";
        }
    }

    //======================================================== Render

    return (
        <div className="grid grid-cols-1 place-items-center">

            <div className="flex gap-2 flex-wrap items-center">
                {challengeStatus === "completed" && <div className={"border-2 select-none text-xs p-3 m-3 w-20 h-16 " + (overViewCardSelected ? " bg-red-200" : " border-gray-300")}
                    onClick={() => { setSelectedMcqIndex(-1); setOverViewCardSelected(true) }}
                >Overview
                </div>}
                {challengeStatus !== "waiting" && filteredMcqs && filteredMcqs.map((mcq, index) => {
                    return (
                        <div key={"mcq-" + index} className={getSelectionCardClassString(index)}
                            onClick={() => { setSelectedMcqIndex(index); setOverViewCardSelected(false) }}
                        >
                            {"# " + (index + 1)}
                        </div>
                    )
                }
                )}
                {challengeStatus === "running" &&
                    <Button
                        disabled={!allAnswersSubmitted()}
                        className="h-12"
                        onClick={() => gradeAnswers()}
                    >Submit Answers</Button>}
            </div>

             {challengeStatus !== "waiting" && challengeStatus !== "error" && filteredMcqs && filteredMcqs[selectedMcqIndex] &&
                <div className="grid grid-cols-1 place-items-left" style={{width:getMcqBoxWidth()}}>                   
                    <RenderPageSnippet contentJSON={JSON.parse(filteredMcqs[selectedMcqIndex].contentDocument)} />
                    {filteredMcqs.length > 0 && filteredMcqs[selectedMcqIndex].answers.map((answer, index) => {
                        return (
                            <div key={answer.id} className="flex">
                                <div
                                    className={"cursor-pointer mr-4 mb-4 size-8 border-2 border-black " + renderChosenAnswerTick(answer.id)}
                                    onClick={() => selectAnswer(filteredMcqs[selectedMcqIndex].id, answer.id)}
                                ></div>
                                <div className="text-xs md:text-xl">{answer.answerText}</div>
                            </div>
                        )
                    })}
                </div>
            }

            {overViewCardSelected &&
                renderOverviewCard()
            }

            {challengeStatus === "error" &&
                <div>
                    <p>Error loading challenge. Please contact support.</p>
                </div>
            }

        </div>
    );
}

export default McqChallengeSystem;

