//FOR VIEWING
//Redirects here ONLY from CourseTextChallenge

//React
import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

//Services/Helpers
import parsePageJSONForVisibility from 'logic/gridslate/nodePage/parsePageJSONForVisibility';
import { useAtom } from 'jotai';
import { courseProgressAtom, courseAtom, loadedMcqs } from 'atom';
import { toast } from 'react-toastify';

//CSS
import "css/prosemirror.css";
import "css/custom-tiptap-styles.css";

//Logics
import { Container } from "view_components/helper/HelperComponents";

//Components
import RenderCourseContent from "views/render/RenderCourseContent";
import CheckpointTimeline from 'views/courseview/courseContentElements/subcomponents/CheckpointTimeline';

//Classes
import { Status } from "classes/enums/Status";
import { CourseContent } from 'classes/course/CourseContent';
import { CourseChallenge } from 'classes/course/CourseChallenge';
import { CheckpointTypes } from 'classes/enums/CheckpointTypes';
import { FilterModel, PropertyFilter } from 'classes/models/request/FilterModel';
import crudService from 'services/crudService';
import { Class } from 'classes/enums/Class';
import { Mcq } from 'classes/synapp/mcq/Mcq';

const ViewTextChallengeSystem = () => {

  const { courseContentId } = useParams();
  const [parsedContent, setParsedContent] = useState<any>("");
  const [courseContent, setCourseContent] = useState<CourseContent | null>(null);
  const [mcqs, setMcqs] = useAtom(loadedMcqs);
  const [courseProgress, setCourseProgress] = useAtom(courseProgressAtom);
  const [courseChallenge, setCourseChallenge] = useState<CourseChallenge | null>(null);
  const [nodeCourse, setCourse] = useAtom(courseAtom);
  const [mcqStatus, setMcqStatus] = useState("unloaded"); //unloaded, loading, loaded, failed

  const navigate = useNavigate();

  //TODO: Redirect back to course page if nodeCourse not loaded
  //OR load nodeCourse here with selective load

  useEffect(() => {
    if (nodeCourse.status === Status.unloaded) {
      toast.error("Course not loaded. Redirecting to course browser.");
      navigate("/CourseBrowser");
    } else {
      //Set course content
      let thisCourseContent = nodeCourse.courseContents.find((x) => x.id === courseContentId);
      if (thisCourseContent) {
        let thisChallengeProgress = courseProgress.challengeProgresses.find((x) => x.courseContentId === courseContentId);
        let thisParsedContent = JSON.parse(thisCourseContent.contentDocument);
        thisParsedContent = parsePageJSONForVisibility(thisParsedContent, thisChallengeProgress);
        setParsedContent(thisParsedContent);
        setCourseContent(thisCourseContent);
        fetchMcqs(thisCourseContent);
      } else {
        toast.error("Course content not found. Redirecting to course page.");
        navigate("/ViewCourse/" + nodeCourse.id);
      }
      //Set course challenge, if it exists
      //TODO: Check limitation: ONLY one challenge per course content, otherwise only the first one will be set
      let thisCourseChallenge = nodeCourse.courseChallenges.find((x) => x.courseContentId === courseContentId);
      if (thisCourseChallenge) {
        setCourseChallenge(thisCourseChallenge);
      } else {
        console.warn("Course challenge not found.");
      }
    }

  }, []);

  const fetchMcqs = async (courseContent: CourseContent) => {
    //Check if challenge has mcq checkpoints
    let mcqCheckpoints = courseContent.checkpoints.filter((x) => x.type == CheckpointTypes.Mcq);
    if (mcqCheckpoints.length > 0) {
      let parentId = courseContent.categoryIds.length > 0 ? courseContent.categoryIds[courseContent.categoryIds.length - 1] : "None";
      if (parentId === "None") {
        console.warn("Course content has no category id.");
        setMcqStatus("failed");
        return;
      }
      setMcqStatus("loading");
      let filter = new FilterModel([new PropertyFilter("CategoryIds", parentId)]);
      let response = await crudService.get(Class.mcq, filter);
      if (response.success) {
        let tempMcqs = response.payload as Mcq[];
        tempMcqs = tempMcqs.concat(mcqs);

        //Remove all duplicates based on ids
        //TODO: Check if this works as expected
        tempMcqs = tempMcqs.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i);
        console.log(tempMcqs.length);
        setMcqs(tempMcqs);
        setMcqStatus("loaded");
      } else {
        console.error("Failed to load mcqs.");
        setMcqStatus("failed");
      }
   
    } else {
      setMcqStatus("loaded");
    }
  }

  //TODO: Parse visibility

  useEffect(() => {
    if (courseProgress.visibilityUpdated) {
      console.log("Visibility updated reached");
      let challengeProgress = courseProgress.challengeProgresses.find((x) => x.courseContentId === courseContentId);
      let thisParsedContent = parsePageJSONForVisibility(parsedContent, challengeProgress);
      setParsedContent(thisParsedContent);
      setCourseProgress({ ...courseProgress, visibilityUpdated: false });
    }
  }, [courseProgress.visibilityUpdated]);

  return (
    <Container>
      {/* <Button onClick={() => parseVisibility()}>Parse visibility</Button> */}
      {/* {JSON.stringify(parsedContent)} */}
     
      {/* {"Challenge: " + JSON.stringify(courseChallenge)}
      <div className="my-4"></div>
      {"Progress: " +  JSON.stringify(courseProgress)}
       */}
       {courseChallenge && <CheckpointTimeline contentDocument={parsedContent} courseChallenge={courseChallenge} />}
      
      <div className="mb-96 pt-12">
        {parsedContent !== "" && mcqStatus === "loaded" &&
          <div>
            {/* {nodePage && nodePage.type === "courseText" && <CheckpointTimeline contentDocument={parsedContent} nodePageId={nodePageId?nodePageId:""} />}   */}
            <div className="max-w-none prose prose-sm sm:prose-base lg:prose-base xl:prose-xl">
              <RenderCourseContent contentJSON={parsedContent} courseChallenge={courseChallenge} renderCheckpoints={true} />
            </div>
          </div>}
        {!courseContent && <div>Loading content...</div>}
        {mcqStatus === "loading" && <div>Loading mcqs...</div>}
        {mcqStatus === "failed" && <div>Failed to load mcqs. Contact support.</div>}
      </div>
    </Container>
  )
}

export default ViewTextChallengeSystem;