
//require('dotenv').config();

//React
import { useEffect, useState } from 'react';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
//import { ScrollRestoration } from "react-router-dom";

//UI
import 'react-toastify/dist/ReactToastify.css';

//Services
import { ToastContainer } from 'react-toastify';
import useS3Fetch from 'services/useS3Fetch';

//Logics
//import UserLogic from 'utility/UserLogic';
import routeLogic from 'logic/gridslate/S3RouteLogic';
import useUser from 'logic/hooks/useUser';
//import constructedPagesLogic from 'logic/gridslate/constructedPagesLogic';

//Components

import Test from 'views/test/Test';
import TestStuff from 'views/test/TestStuff';
import TestCamera from 'views/test/TestCamera';
//import TestTurtle from 'views/test/TestTurtle';
import GridslateNavbar from "views/siteview/GridslateNavbar";
import Login from 'views/accounts/Login';
import Logout from 'views/accounts/Logout';
//REDACT GridslateConstructor????

//import NavbarConstructor from 'components/gridslate/NavbarConstructor';
import Home from "views/siteview/pages/Home";
import NotFound from 'views/siteview/pages/NotFound'

import AccountManager from './views/admin/AccountManager';
import AdminHandler from './views/admin/AdminHandler';
import MyAccount from 'views/accounts/MyAccount';

import HelpPage  from 'views/siteview/pages/Help';

import RenderPreConstructedPage from "views/render/RenderCustomPage";

import CourseConstructor from "views/courseconstruction/CourseConstructor";
import ViewTextChallenge from "views/courseview/challenge/ViewTextChallengeSystem";

import BreadcrumbBar from 'views/siteview/BreadcrumbBar';

import ViewPage from 'views/siteview/ViewPage';
import CourseTextViewWrapper from 'views/courseview/CourseTextViewWrapper';
import CourseBrowser from 'views/courseview/CourseBrowser';

import TestTurtle from 'views/custom/tools/Turtle';
import CodePlayground from 'views/custom/tools/CodePlayground';

import StageFlashGame from 'views/redactreview/synapp/course/StageFlashGame';
import RunFlashGame from 'views/redactreview/synapp/slides/RunFlashGame';

//Classes
import { NavbarElement } from 'classes/gridslate/navbar/NavbarElement';
import { NavbarChild } from 'classes/gridslate/navbar/NavbarChild';
import { NavbarClass } from 'classes/gridslate/navbar/Navbar';
import TestContextify from 'views/test/TestContextify';
//import TestTipTap from 'views/test/TiptapTest/TestTipTap';
import ViewCourse from "views/courseview/ViewCourse"; 
import CodeExerciseChallengeWrapper from "views/courseview/challenge/CodeExerciseChallengeWrapper";
import NavbarConstructor from 'views/siteconstruction/NavbarConstructor';

function App() {

  const { payload: navbarS3, isPending: isPendingNavbar, error: errorNavbar } = useS3Fetch(routeLogic.GetNavbarRoute());
  const [navbar, setNavbar] = useState<NavbarClass>();

  // "homepage": "https://synapp.org" adding to package.json did not work
  //console.log(process.env.NODE_ENV + " " +  process.env.PUBLIC_URL + " Test: " + process.env.TEST);

  useEffect(() => {
    setNavbar(navbarS3 as unknown as NavbarClass);
  }, [navbarS3]);

  useUser();

  return (
    <div>
      <Router>
        {/* <ScrollRestoration /> */}
        <ToastContainer />
        <GridslateNavbar navbar={navbar} />
        <BreadcrumbBar />

        <Routes>

          {/* ==================== DYNAMIC routes, based on navbar.json */}

          {navbar && navbar.elements.map((topLevelElement: NavbarElement, index: number) => (
            <React.Fragment key={'navroute_top_' + index}>
              {topLevelElement.children.length === 0 && topLevelElement.type === 0 &&
                <Route
                  path={"/" + topLevelElement.route}
                  element={<ViewPage pageId={topLevelElement.pageId} />}
                />}
              {topLevelElement.children.length === 0 && topLevelElement.type === 1 &&
                <Route
                  path={topLevelElement.route}
                  element={<RenderPreConstructedPage name={topLevelElement.pageId} />}
                />}
              {topLevelElement.children.length > 0 && topLevelElement.children.map((child: NavbarChild, index2: number) => (
                <React.Fragment key={'navroute_child_' + index2}>
                  {child.type === 0 &&
                    <Route
                      path={"/"+ topLevelElement.route + "/" + child.route}
                      element={<ViewPage pageId={child.pageId} />}
                    />}
                  {child.type === 1 &&
                    <Route
                      path={"/" + topLevelElement.route + "/" + child.route}
                      element={<RenderPreConstructedPage name={child.pageId} />}
                    />}
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}

          {/* ==================== CUSTOM routes */}

          <Route path="/" element={<Home />} />
          <Route path="/test" element={<Test />} />
          <Route path="/teststuff" element={<TestStuff />} />
          <Route path="/testcamera" element={<TestCamera />} />
          <Route path="/testcontextify" element={<TestContextify />} />

          <Route path="/help" element={<HelpPage />} />
         
          {/* ==================== Constructor routes */}

          <Route path="/CourseConstructor" element={<CourseConstructor />} />
          <Route path="/SiteConstruction/NavbarConstructor" element={<NavbarConstructor pages={null} />} />

          {/* ==================== Viewer routes */}

          <Route path="/CourseBrowser" element={<CourseBrowser />} />
          <Route path="/ViewCourse/:courseId" element={<ViewCourse />} />
          <Route path="/CodeExerciseChallengeWrapper/:codeChallegeId" element={<CodeExerciseChallengeWrapper />} />
          <Route path={`/ViewTextChallenge/:courseContentId`} element={<ViewTextChallenge/>} />
          <Route path={`/CourseTextViewWrapper/:courseContentId`} element={<CourseTextViewWrapper/>} />

          {/* ==================== Tools routes */}

          <Route path="/Turtle" element={<TestTurtle />} />
          <Route path="/CodePlayground" element={<CodePlayground />} />

          <Route path="/Wordflash/Stage/:type/:id" element={<StageFlashGame />} />
          <Route path="/Wordflash/Run" element={<RunFlashGame />} />

          {/* ==================== Admin routes */}
          <Route path="/admin/accountmanager" element={<AccountManager />} />
          <Route path="/admin/adminhandler" element={<AdminHandler />} />

          {/* ==================== ACCOUNT routes */}

          {/* <Route path="/accounts/myaccount" element={<MyAccount />} /> */}
          <Route path="/accounts/login" element={<Login />} />
          <Route path="/accounts/logout" element={<Logout />} />
          <Route path="/accounts/myaccount" element={<MyAccount />} />
            
            {/* ==================== 404 route */}

          <Route path="*" element={<NotFound />} />
          {/* <Route path="*" element={null} /> */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;