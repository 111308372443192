//React
import { useEffect, useState, useRef } from 'react';

//UI
import { Button, Modal, Textarea, Spinner } from 'flowbite-react';
import { Row, CheckboxAndLabel } from 'view_components/helper/HelperComponents';

//Services
import apiService from 'services/apiService';
import crudService from 'services/crudService';
import config from 'config';
import { toast } from 'react-toastify';
import errorService from 'services/errorService';
import { useAtom } from 'jotai';
import { loadedMcqs } from 'atom';
import createGUID from 'logic/utility/createGUID';

//Logics
import parseMcqAILogic from "logic/ai/parseMcqAILogic";

//Components
import McqTipTapEditor from "views/courseconstruction/McqTiptapEditor";

//Classes
import { FilterModel, PropertyFilter } from "classes/models/request/FilterModel";
import { Class } from 'classes/enums/Class';
import { Status } from 'classes/enums/Status';
import { Mcq } from "classes/synapp/mcq/Mcq";
import { McqAnswer } from "classes/synapp/mcq/McqAnswer";
import { IsBusy } from 'classes/general/IsBusy';
import { McqTiptapEditorInterface } from 'classes/interfaces/McqTiptapEditorInterface';
import CategoryManager from './CategoryManager';

type Props = {
    content: string;
    getContent: Function;
    baseCategoryIds: string[];
}

const McqConstructor = (props: Props) => {

    const { content, baseCategoryIds } = props;

    const tiptapEditorReference = useRef<McqTiptapEditorInterface>(null);
    const [mcqs, setMcqs] = useAtom(loadedMcqs);
    const [selectedMcqLocalId, setSelectedMcqLocalId] = useState<string>("None");
    const [difficultySetting, setDifficultySetting] = useState("1");
    const [numberOfQuestionsToGenerate, setNumberOfQuestionsToGenerate] = useState("3");
    const [showMcqJson, setShowMcqJson] = useState(false);
    const [isBusy, setIsBusy] = useState(new IsBusy());
    const [mcqAIContentForPrompt, setMcqAIContentForPrompt] = useState(content);
    const [showAIGenerationModal, setShowAIGenerationModal] = useState(false);

    useEffect(() => {
        let parentId = baseCategoryIds.length > 0 ? baseCategoryIds[baseCategoryIds.length - 1] : "None";
        let tempMcqs = mcqs.filter(x => x.categoryIds.includes(parentId));
        if (tempMcqs.length > 0) {
            setSelectedMcqLocalId(tempMcqs[0].id);
        } else {
            setSelectedMcqLocalId("None");
        }

    }, [baseCategoryIds]);

    useEffect(() => {
        if (content !== "") {
            setMcqAIContentForPrompt(content);
        }
    }, [content]);

    useEffect(() => {
        setShowAIGenerationModal(isBusy.isGenerating);
    }, [isBusy.isGenerating]);

    const createMcq = () => {
        let newMcq = new Mcq();

        let contentObject = {
            "type": "doc",
            "content": [{
                "type": "heading",
                "attrs": {
                    "elementId": createGUID(10),
                    "color": null,
                    "textAlign": "left",
                    "level": 3
                },
                "content": [
                    {
                        "type": "text",
                        "text": "Question text"
                    }
                ]
            }
            ]
        };

        newMcq.contentDocument = JSON.stringify(contentObject);

        newMcq.categoryIds = baseCategoryIds;
        newMcq.difficulty = parseInt(difficultySetting);
        newMcq.status = Status.new;
        newMcq.answers = [new McqAnswer(), new McqAnswer(), new McqAnswer(), new McqAnswer()];

        let tempMcqs = [...mcqs];
        tempMcqs.push(newMcq);
        changeMcqSelection(newMcq.id);
        setMcqs(tempMcqs);
    }

    //================================================================================================= - Edit Mcq


    const editMcq = (action: string, value: any, index: number) => {
        let mcqIndex = mcqs.findIndex(x => x.id === selectedMcqLocalId);
        if (mcqIndex === -1) {
            return;
        }

        let tempMcqs = [...mcqs];
        let tempMcq = { ...tempMcqs[mcqIndex] };

        if (tempMcq.status === Status.unchanged) {
            tempMcq.status = Status.updated;
        }

        if (!tempMcq || tempMcq.answers === undefined) {
            return;
        }
        if (action === "editDifficulty") {
            tempMcq.difficulty = parseInt(value);
        } else if (action === "editAnswerText") {
            tempMcq.answers[index].answerText = value;
        } else if (action === "editAnswerIsCorrect") {
            tempMcq.answers[index].isCorrect = !tempMcq.answers[index].isCorrect;
        } else if (action === "addAnswer") {
            tempMcq.answers.push(new McqAnswer());
        } else if (action === "deleteAnswer") {
            tempMcq.answers.splice(index, 1);
        } else if (action === "editIsSelfContained") {
            tempMcq.isSelfContained = value;
        } else if (action === "editIsReviewed") {
            tempMcq.isReviewed = value;
        } else if (action === "editCategoryIds") {
            tempMcq.categoryIds = value;
        }
        tempMcqs[mcqIndex] = tempMcq;
        setMcqs(tempMcqs);
    }

    const deleteMcq = () => {
        let mcqIndex = mcqs.findIndex(x => x.id === selectedMcqLocalId);
        if (mcqIndex === -1) {
            return;
        }

        if (mcqs[mcqIndex].status === Status.new) {
            let tempMcqs = [...mcqs];
            tempMcqs.splice(mcqIndex, 1);
            setMcqs(tempMcqs);
            setSelectedMcqLocalId("None");
        } else {
            //delete from db
            let filterModel = new FilterModel([new PropertyFilter("Id", mcqs[mcqIndex].id)]);
            crudService.del(Class.mcq, filterModel).then(response => {
                if (response.success) {
                    let tempMcqs = [...mcqs];
                    tempMcqs.splice(mcqIndex, 1);
                    setMcqs(tempMcqs);
                    setSelectedMcqLocalId("None");
                } else {
                    errorService.handleError(response);
                }
            });
        }

    }

    //================================================================================================= - Save/Load Mcq

    const changeMcqSelection = (id: string) => {

        //Currently selected mcq
        let mcqIndex = mcqs.findIndex(x => x.id === selectedMcqLocalId);
        if (mcqIndex !== -1) {

            //Persist changes to current mcq
            let updatedComponentJSONString = tiptapEditorReference.current?.getContents();

            if (updatedComponentJSONString) {
                let tempMcqs = [...mcqs];
                let tempMcq = { ...tempMcqs[mcqIndex] };
                tempMcq.contentDocument = updatedComponentJSONString;
                tempMcqs[mcqIndex] = tempMcq;
                setMcqs(tempMcqs);
            }
        }

        setSelectedMcqLocalId(id);
    }

    const saveMcqs = async () => {

        //check validity of all mcqs
        for (let mcq of mcqs) {
            if (!checkForValidity(mcq)) {
                alert("Invalid mcq found. Please check all mcqs (in red) for validity before saving.");
                return;
            }
        }

        let requestModelList = mcqs.filter(x => x.status === Status.new || x.status === Status.updated);

        //If currently selected mcq is new or updated, update its content
        let mcqIndex = requestModelList.findIndex(x => x.id === selectedMcqLocalId);
        if (mcqIndex !== -1) {
            let updatedComponentJSONString = tiptapEditorReference.current?.getContents();
            if (updatedComponentJSONString) {
                requestModelList[mcqIndex].contentDocument = updatedComponentJSONString;
            }
        }

        //TODO: parse data property when necessary
        //TODO: parse for problems like empty question or answers or no correct answer selected

        let response = await crudService.create(Class.mcq, requestModelList);
        if (response.success) {
            let responseModel = response.payload as Mcq[];
            let tempMcqs = [...mcqs];

            for (let i = 0; i < responseModel.length; i++) {
                let index = tempMcqs.findIndex(x => x.id === responseModel[i].id);
                tempMcqs[index] = responseModel[i];
            }
            toast.success("MCQs saved successfully");
            setMcqs(tempMcqs);
        } else {
            errorService.handleError(response);
        }
    }

    //============================================================ - Category Constructor, Selector and Helper Functions


    //============================================================= - AI Generator


    const parseAndCreateAIGeneratedMcqs = (response: string) => {

        let tempMcqs = parseMcqAILogic.parseAIResponseSchemaToMcqs(response);

        for (let mcq of tempMcqs) {
            mcq.categoryIds = baseCategoryIds;
            mcq.status = Status.new;
        }
        //Add to existing mcqs
        let allTempMcqs = [...mcqs];
        allTempMcqs = allTempMcqs.concat([...tempMcqs]);
        setMcqs(allTempMcqs);
    }



    const generateMcqsFromAI = async () => {
        setIsBusy(isBusy.generating());
        let request = { "Content": mcqAIContentForPrompt, "NumberOfQuestions": numberOfQuestionsToGenerate, "Difficulty": difficultySetting };
        let response = await apiService.post(config.apiUrl + '/cms/AICreateCodeMcq', request);
        if (response.success) {
            parseAndCreateAIGeneratedMcqs(response.payload);
            setIsBusy(isBusy.reset());
            setShowAIGenerationModal(false);
        } else {
            errorService.handleError(response);
            setIsBusy(isBusy.reset());
        }

    }

    const RenderAIGenerationModal = () => {

        const [isGenerating, setIsGenerating] = useState(false);

        return (<Modal show={showAIGenerationModal} size="5xl" onClose={() => setShowAIGenerationModal(false)} popup>
            <Modal.Header />
            <Modal.Body>
                <div className="my-10">
                    {"Busy:" + isBusy.isBusy}
                    <div>{"Difficulty:" + difficultySetting}</div>
                    <select disabled={isBusy.isBusy} value={difficultySetting} onChange={(e) => { setDifficultySetting(e.target.value) }}>
                        <option value="different">Different difficulties</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                    </select>
                    <div>Number of questions to generate: </div>
                    <select disabled={isBusy.isBusy} value={numberOfQuestionsToGenerate} onChange={(e) => { setNumberOfQuestionsToGenerate(e.target.value) }}>
                        <option value="1">1</option>
                        <option value="3">3</option>
                        <option value="5">5</option>
                        <option value="10">10</option>
                    </select>
                    {/* <Button onClick={() => { setSelectingContentPending(true); setShowAIGenerationModal(false); }}>Select Content</Button> */}
                    <div>Content</div>
                    <div className="flex gap-2">
                    </div>
                    <div>
                        <Textarea placeholder="Content for generating mcqs..." required rows={8} value={mcqAIContentForPrompt} onChange={(e) => setMcqAIContentForPrompt(e.target.value)} />
                    </div>

                    <div className="flex gap-2 my-6">
                        <Button disabled={mcqAIContentForPrompt === "" || isGenerating} onClick={() => generateMcqsFromAI()}>Generate</Button>
                    </div>
                    {isGenerating && <div className="flex justify-center gap-2">
                        <div className="w-32">
                            <Spinner size="large" />
                            Generating...
                        </div>
                    </div>}
                </div>
            </Modal.Body>
        </Modal>);
    }


    const contentChanged = (content: any) => {
        let foundIndex = mcqs.findIndex(x => x.id === selectedMcqLocalId);


        if (foundIndex === -1) {
            console.log("Content changed: Mcq not found in mcqs: ", selectedMcqLocalId);
            return;
        }

        if (mcqs[foundIndex].status === Status.unchanged) {
            let tempMcqs = [...mcqs];
            let tempMcq = { ...tempMcqs[foundIndex] };
            tempMcq.status = Status.updated;
            tempMcqs[foundIndex] = tempMcq;
            setMcqs(tempMcqs);
        }

    }

    //================================================================================== - Render Mcq

    const RenderMcq = () => {

        const [showCategoryIds, setShowCategoryIds] = useState(false);

        let foundIndex = mcqs.findIndex(x => x.id === selectedMcqLocalId);
        if (foundIndex === -1) {
            console.log("RenderMcq: Mcq not found in mcqs: ", selectedMcqLocalId);
            return <div>Mcq not found</div>;
        }
        let mcq = mcqs[foundIndex];

        return (
            <div>

                <Row>
                    Difficulty:
                    <select value={mcq.difficulty} onChange={(e) => { editMcq("editDifficulty", e.target.value, 0) }}>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                    </select>
                    <Button onClick={() => setShowCategoryIds(!showCategoryIds)}>Show Category Ids</Button>
                    {showCategoryIds && <CategoryManager
                        categoryIds={mcq.categoryIds}
                        setCategoryIds={(categoryIds: any) => editMcq("editCategoryIds", categoryIds, 0)}
                        editable={true}
                    />}
                    {showCategoryIds && <div>{mcq.categoryIds.join("; ")}</div>}
                    <CheckboxAndLabel checked={mcq.isSelfContained} label="Is Self-Contained" onChange={(e: any) => { editMcq("editIsSelfContained", e.target.checked, 0) }} />
                    <CheckboxAndLabel checked={mcq.isReviewed} label="Is Reviewed" onChange={(e: any) => { editMcq("editIsReviewed", e.target.checked, 0) }} />
                </Row>
                <McqTipTapEditor ref={tiptapEditorReference} content={mcq.contentDocument} contentChanged={contentChanged} mcqId={mcq.id} />
                <Row>
                    Answers:
                    <div className="">
                        {mcq.answers.map((answer, index) => {
                            return (
                                <Row key={"mcq-answers-" + index} className="flex gap-4 my-6">

                                    <CheckboxAndLabel checked={answer.isCorrect} label="Is Correct" onChange={(e: any) => { editMcq("editAnswerIsCorrect", null, index) }} />
                                    <input className="w-[800px]" type="text" placeholder='Answer text here...' value={answer.answerText} onChange={(e) => { editMcq("editAnswerText", e.target.value, index) }} />

                                    <Button onClick={() => editMcq("deleteAnswer", index, 0)}>Delete</Button>

                                </Row>
                            )
                        })}
                        <Button onClick={() => editMcq("addAnswer", null, 0)}>Add Answer</Button>
                    </div>

                </Row>
            </div>
        )
    }

    const checkForValidity = (mcq: Mcq) => {
        let valid = true;
        // if (mcq.questionText === "") {
        //     valid = false;
        // }
        if (mcq.answers.length === 0) {
            valid = false;
        }
        let correctAnswerCount = 0;
        let validAnswerTextCount = 0;
        for (let answer of mcq.answers) {
            if (answer.isCorrect) {
                correctAnswerCount++;
            }
            if (answer.answerText !== "") {
                validAnswerTextCount++;
            }
        }
        if (correctAnswerCount === 0) {
            valid = false;
        }
        if (validAnswerTextCount < 2) {
            valid = false;
        }
        return valid;
    }

    const getSelectionCardClassString = (elementId: string) => {
        let classString = "border-2 select-none text-xs p-3 m-3 w-20 h-16 ";
        classString += (selectedMcqLocalId === elementId) ? " border-black" : " border-gray-300";
        let foundIndex = mcqs.findIndex(x => x.id === elementId);
        if (foundIndex === -1) {
            console.log("Mcq not found in mcqs: ", elementId);
            return classString;
        }
        let thisMcq = mcqs[foundIndex];
        //let thisAnswer = submittedAnswers.find(x => x.mcqId === filteredMcqs[index].id);
        if (thisMcq.status === Status.unchanged) {
            classString += " bg-gray-100";
        }
        else if (thisMcq.status === Status.new) {
            if (checkForValidity(thisMcq)) {
                classString += " bg-green-200";
            } else {
                classString += " bg-red-200";
            }
        } else if (thisMcq.status === Status.updated) {
            classString += " bg-yellow-200";
        }
        return classString;
    }

    const getFilteredMcqs = () => {
        let parentId = baseCategoryIds.length > 0 ? baseCategoryIds[baseCategoryIds.length - 1] : "None";
        let tempMcqs = mcqs.filter(x => x.categoryIds.includes(parentId));
        return tempMcqs;
    }

    const duplicateMcq = () => {
        let foundIndex = mcqs.findIndex(x => x.id === selectedMcqLocalId);
        if (foundIndex === -1) {
            console.log("Mcq not found in mcqs: ", selectedMcqLocalId);
            return;
        }
        let mcq = mcqs[foundIndex];
        let newMcq = new Mcq();
        newMcq.id = createGUID(10);
        newMcq.isSelfContained = mcq.isSelfContained;
        newMcq.contentDocument = mcq.contentDocument;
        newMcq.categoryIds = [...mcq.categoryIds];
        newMcq.difficulty = mcq.difficulty;
        newMcq.status = Status.new;
        newMcq.statusCode = 0; //TODO: Make enum
        //Copy answers and generate new ids for each
        newMcq.answers = mcq.answers.map(x => { return { ...x } });
        newMcq.answers.forEach(x => x.id = createGUID(10));

        let tempMcqs = [...mcqs];
        tempMcqs.push(newMcq);
        changeMcqSelection(newMcq.id);
        setMcqs(tempMcqs);
    }

    return (
        <div className='container mx-auto'>
            {/* <Button onClick={() => testParsing()}>Test Parsing</Button> */}
            {/* <div>{JSON.stringify(mcqs.find(x => x.elementId == selectedMcqLocalId))}</div> */}
            <div className="flex gap-2 my-6">
                <Button onClick={() => createMcq()}>Create Plain MCQ</Button>
                <Button onClick={() => saveMcqs()}>Save MCQs</Button>
                <Button onClick={() => duplicateMcq()}>Duplicate</Button>
                <Button onClick={() => setShowAIGenerationModal(true)}>Generate MCQs</Button>
                <Button disabled={selectedMcqLocalId === "None"} onClick={() => deleteMcq()}>Delete</Button>
                <Button onClick={() => setShowMcqJson(!showMcqJson)}>Show JSON</Button>
            </div>
            <div className="flex gap-2 flex-wrap">
                {/* {mcqs && mcqs.filter(x => x.categoryIds.includes(baseCategoryIds.length > 0 ? baseCategoryIds[baseCategoryIds.length - 1] : "None")).map((mcq, index) => { */}
                {mcqs && getFilteredMcqs().map((mcq, index) => {
                    return (
                        <div key={"mcq-" + index} className={getSelectionCardClassString(mcq.id)}
                            onClick={() => { changeMcqSelection(mcq.id) }}
                        >
                            {"Dif: " + mcq.difficulty}
                            {mcq.isReviewed && <span className={"text-3xl text-yellow-500"}>*</span>}
                        </div>
                    )
                }
                )}
            </div>
            {showMcqJson && <div>{JSON.stringify(mcqs.find(x => x.id === selectedMcqLocalId))}</div>}
            <div className="grid grid-cols-12">
                <div className="col-span-12">
                    {selectedMcqLocalId !== "None" && <RenderMcq />}
                </div>
            </div>

            {showAIGenerationModal && <RenderAIGenerationModal />}

        </div>
    )

}

export default McqConstructor;
