import {atom} from 'jotai';
import { User } from 'classes/accounts/User';

import {Mcq} from 'classes/synapp/mcq/Mcq';
import { NavbarClass } from 'classes/gridslate/navbar/Navbar';
import {Page} from 'classes/Page';
import {Course} from "classes/course/Course";

import {CourseProgress} from 'classes/courseprogress/CourseProgress';
import { Category } from 'classes/synapp/Category';
import { BreadCrumbItem } from 'classes/general/BreadcrumbItem';
import { CodeExercise } from 'classes/synapp/code_exercise/CodeExercise';
import { CourseEnrollment } from 'classes/course/CourseEnrollment';

export const userAtom = atom(new User());

export const categoriesAtom = atom([] as Category[]);
export const baseCategoryIdsAtom = atom([] as string[]);
export const editableNavbarAtom = atom(new NavbarClass());

export const loadedMcqs = atom([] as Mcq[]);

export const courseProgressAtom = atom(new CourseProgress());

export const courseAtom = atom(new Course());
export const pageAtom = atom(new Page());

export const breadCrumbsAtom = atom([] as BreadCrumbItem[]);

export const codeExercisesAtom = atom([] as CodeExercise[]);

//Course managements

export const totalCourseProgressAtom = atom([] as CourseProgress[]);
export const courseEnrollmentsAtom = atom([] as CourseEnrollment[]);