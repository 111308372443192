
//========================================================= CONTROLS

const incrementIcon = (size: number, color: string) => {
    let classString = "w-[" + size + "px] h-[" + size + "px] text-" + color;
    return (<svg className={classString} width={size} height={size} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 2v14M2 9h14" />
    </svg>
    )
}

const decrementIcon = (size: number, color: string) => {
    let classString = "w-[" + size + "px] h-[" + size + "px] text-" + color;
    return (<svg className={classString} width={size} height={size} aria-hidden="true" fill="none" viewBox="0 0 18 18">
        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2 9h14" />
    </svg>
    )
}

const fontSizeIcon = (size: number, color: string) => {
    let classString = "w-[" + size + "px] h-[" + size + "px] text-" + color;
    return (<svg className={classString} width={size} height={size} aria-hidden="true" fill="none" viewBox="0 0 24 24">
        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M3 6.2V5h11v1.2M8 5v14m-3 0h6m2-6.8V11h8v1.2M17 11v8m-1.5 0h3" />
    </svg>)

    //    <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
    //   <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 6.2V5h11v1.2M8 5v14m-3 0h6m2-6.8V11h8v1.2M17 11v8m-1.5 0h3"/>
    // </svg>
}

const fontFamilyIcon = (size: number, color: string) => {
    let classString = "w-[" + size + "px] h-[" + size + "px] text-" + color;
    return (<svg className={classString} width={size} height={size} aria-hidden="true" fill="none" viewBox="0 0 24 24">
        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m10.5785 19 4.2979-10.92966c.0369-.09379.1674-.09379.2042 0L19.3785 19m-8.8 0H9.47851m1.09999 0h1.65m7.15 0h-1.65m1.65 0h1.1m-7.7-3.9846h4.4M3 16l1.56685-3.9846m0 0 2.73102-6.94506c.03688-.09379.16738-.09379.20426 0l2.50367 6.94506H4.56685Z" />
    </svg>

    )
}

const undoIcon = (size: number, color: string) => {
    //color="red-500";
    let classString = "w-[" + size + "px] h-[" + size + "px] text-" + color;
    return (<svg className={classString} width={size} height={size} aria-hidden="true" fill="none" viewBox="0 0 24 24">
        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 9h13a5 5 0 0 1 0 10H7M3 9l4-4M3 9l4 4" />
    </svg>
    )
}

const redoIcon = (size: number, color: string) => {
    let classString = "w-[" + size + "px] h-[" + size + "px] text-" + color;
    return (<svg className={classString} width={size} height={size} aria-hidden="true" fill="none" viewBox="0 0 24 24">
        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 9H8a5 5 0 0 0 0 10h9m4-10-4-4m4 4-4 4" />
    </svg>
    )
}

const alignmentLeftIcon = (size: number, color: string) => {
    let classString = "w-[" + size + "px] h-[" + size + "px] text-" + color;
    return (<svg className={classString} width={size} height={size} aria-hidden="true" fill="none" viewBox="0 0 24 24">
        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.4" d="M6 6h8M6 10h12M6 14h8M6 18h12" />
    </svg>)
}

const alignmentCenterIcon = (size: number, color: string) => {
    let classString = "w-[" + size + "px] h-[" + size + "px] text-" + color;
    return (<svg className={classString} width={size} height={size} aria-hidden="true" fill="none" viewBox="0 0 24 24">
        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 6h8M6 10h12M8 14h8M6 18h12" />
    </svg>
    )
}

const alignmentJustifyIcon = (size: number, color: string) => {
    let classString = "w-[" + size + "px] h-[" + size + "px] text-" + color;
    return (<svg className={classString} width={size} height={size} aria-hidden="true" fill="none" viewBox="0 0 24 24">
        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.4" d="M6 6h12M6 10h12M6 14h12M6 18h12" />
    </svg>)
}

const alignmentRightIcon = (size: number, color: string) => {
    let classString = "w-[" + size + "px] h-[" + size + "px] text-" + color;
    return (<svg className={classString} width={size} height={size} aria-hidden="true" fill="none" viewBox="0 0 24 24">
        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.4" d="M10 6h8M6 10h12M10 14h8M6 18h12" />
    </svg>)
}

const penIcon = (size: number, color: string) => {
    let classString = "w-[" + size + "px] h-[" + size + "px] text-" + color;
    return (<svg className={classString} width={size} height={size} aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
        <path fillRule="evenodd" d="M14 4.182A4.136 4.136 0 0 1 16.9 3c1.087 0 2.13.425 2.899 1.182A4.01 4.01 0 0 1 21 7.037c0 1.068-.43 2.092-1.194 2.849L18.5 11.214l-5.8-5.71 1.287-1.31.012-.012Zm-2.717 2.763L6.186 12.13l2.175 2.141 5.063-5.218-2.141-2.108Zm-6.25 6.886-1.98 5.849a.992.992 0 0 0 .245 1.026 1.03 1.03 0 0 0 1.043.242L10.282 19l-5.25-5.168Zm6.954 4.01 5.096-5.186-2.218-2.183-5.063 5.218 2.185 2.15Z" clipRule="evenodd" />
    </svg>)
}

//========================================================= MARKS

const italicIcon = (size: number, color: string) => {
    let classString = "w-[" + size + "px] h-[" + size + "px] text-" + color;
    return (<svg className={classString} width={size} height={size} aria-hidden="true" fill="none" viewBox="0 0 24 24">
        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="m8.874 19 6.143-14M6 19h6.33m-.66-14H18" />
    </svg>)
}

const underlineIcon = (size: number, color: string) => {
    let classString = "w-[" + size + "px] h-[" + size + "px] text-" + color;
    return (<svg className={classString} width={size} height={size} aria-hidden="true" fill="none" viewBox="0 0 24 24">
        <path stroke="currentColor" strokeLinecap="round" strokeWidth="1" d="M6 19h12M8 5v9a4 4 0 0 0 8 0V5M6 5h4m4 0h4" />
    </svg>)
}

const boldIcon = (size: number, color: string) => {
    let classString = "w-[" + size + "px] h-[" + size + "px] text-" + color;
    return (<svg className={classString} width={size} height={size} aria-hidden="true" fill="none" viewBox="0 0 24 24">
        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M8 5h4.5a3.5 3.5 0 1 1 0 7H8m0-7v7m0-7H6m2 7h6.5a3.5 3.5 0 1 1 0 7H8m0-7v7m0 0H6" />
    </svg>)
}

const textColorIcon = (size: number, color: string) => {
    let classString = "w-[" + size + "px] h-[" + size + "px] text-" + color;
    return (<svg className={classString} width={size} height={size} aria-hidden="true" fill={color} viewBox="0 0 24 24">
        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M12 14l9-5-9-5-9 5 9 5z" />
    </svg>)
}

const strikeIcon = (size: number, color: string) => {
    let classString = "w-[" + size + "px] h-[" + size + "px] text-" + color;
    return (<svg className={classString} width={size} height={size} aria-hidden="true" fill="none" viewBox="0 0 24 24">
        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M6 12h12" />
    </svg>)
}

const highlightColorIcon = (size: number, color: string) => {
    let classString = "w-[" + size + "px] h-[" + size + "px] text-" + color;
    return (<svg className={classString} width={size} height={size} aria-hidden="true" fill={color} viewBox="0 0 24 24">
        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M12 14l9-5-9-5-9 5 9 5z" />
    </svg>)
}

const linkIcon = (size: number, color: string) => {
    let classString = "w-[" + size + "px] h-[" + size + "px] text-" + color;
    return (
        <svg className={classString} width={size} height={size} aria-hidden="true" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13.213 9.787a3.391 3.391 0 0 0-4.795 0l-3.425 3.426a3.39 3.39 0 0 0 4.795 4.794l.321-.304m-.321-4.49a3.39 3.39 0 0 0 4.795 0l3.424-3.426a3.39 3.39 0 0 0-4.794-4.795l-1.028.961" />
        </svg>

    )
}

const codeIcon = (size: number, color: string) => {
    let classString = "w-[" + size + "px] h-[" + size + "px] text-" + color;
    return (
        <svg className={classString} width={size} height={size} aria-hidden="true" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m8 8-4 4 4 4m8 0 4-4-4-4m-2-3-4 14" />
        </svg>
    )
}

const footnoteIcon = (size: number, color: string) => {
    let classString = "w-[" + size + "px] h-[" + size + "px] text-" + color;
    return (
        <svg className={classString} width={size} height={size} aria-hidden="true" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7.556 8.5h8m-8 3.5H12m7.111-7H4.89a.896.896 0 0 0-.629.256.868.868 0 0 0-.26.619v9.25c0 .232.094.455.26.619A.896.896 0 0 0 4.89 16H9l3 4 3-4h4.111a.896.896 0 0 0 .629-.256.868.868 0 0 0 .26-.619v-9.25a.868.868 0 0 0-.26-.619.896.896 0 0 0-.63-.256Z" />
        </svg>

    )
}

const superscriptIcon = (size: number, color: string) => {
    let classString = "w-[" + size + "px] h-[" + size + "px] text-" + color;
    return (<svg className={classString} width={size} height={size} aria-hidden="true" fill="none" viewBox="0 0 24 24">
        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20 10h-4v-.5C17.0989 8.46711 19.75 7 19.75 6V4.99989c0-.55228-.4477-.99989-1-.99989H17c-.5523 0-1 .44772-1 1M4 7.30341l9.1221 11.39319m0-11.39319L4 18.6966" />
    </svg>
    )
}

const subscriptIcon = (size: number, color: string) => {
    let classString = "w-[" + size + "px] h-[" + size + "px] text-" + color;
    return (
        <svg className={classString} width={size} height={size} aria-hidden="true" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20 20h-4v-.5c1.0989-1.0329 3.75-2.5 3.75-3.5v-1.0001c0-.5523-.4477-.9999-1-.9999H17c-.5522 0-1 .4477-1 1M4.00004 4l9.12206 11.3932m0-11.3932L4 15.3932" />
        </svg>)
}

const clearHighlightIcon = (size: number, color: string) => {
    let classString = "w-[" + size + "px] h-[" + size + "px] text-" + color;
    return (
        <svg className={classString} width={size} height={size} aria-hidden="true" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 6.2V5h12v1.2M7 19h6m.2-14-1.677 6.523M9.6 19l1.029-4M5 5l6.523 6.523M19 19l-7.477-7.477" />
        </svg>

    )
}

const unlinkIcon = (size: number, color: string) => {
    let classString = "w-[" + size + "px] h-[" + size + "px] text-" + color;
    return (
        <svg className={classString} width={size} height={size} aria-hidden="true" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" strokeLinecap="round" strokeWidth="2" d="M13.2131 9.78732c-.6359-.63557-1.4983-.99259-2.3974-.99259-.89911 0-1.76143.35702-2.39741.99259l-3.4253 3.42528C4.35719 13.8485 4 14.7108 4 15.61c0 .8992.35719 1.7616.99299 2.3974.63598.6356 1.4983.9926 2.39742.9926.89912 0 1.76144-.357 2.39742-.9926l.32157-.3043m-.32157-4.4905c.63587.6358 1.49827.993 2.39747.993.8991 0 1.7615-.3572 2.3974-.993l3.4243-3.42528c.6358-.63585.993-1.49822.993-2.39741 0-.89919-.3572-1.76156-.993-2.39741C17.3712 4.357 16.509 4 15.6101 4c-.899 0-1.7612.357-2.397.9925l-1.0278.96062m7.3873 14.04678-1.7862-1.7862m0 0L16 16.4274m1.7864 1.7863 1.7862-1.7863m-1.7862 1.7863L16 20" />
        </svg>

    )
}

const clearAllIcon = (size: number, color: string) => {
    let classString = "w-[" + size + "px] h-[" + size + "px] text-" + color;
    return (
        <svg className={classString} width={size} height={size} aria-hidden="true" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 13V8m0 8h.01M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
        </svg>

    )
}


//========================================================= NODES

//An icon of a stylized P
const paragraphIcon = (size: number, color: string) => {
    let classString = "w-[" + size + "px] h-[" + size + "px] text-" + color;
    //return (<svg className={classString} width={size} height={size} aria-hidden="true" fill="none" viewBox="0 0 24 24">
    return (<svg className={classString} width={size} height={size} aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
        <path fill-rule="evenodd" d="M8.5 4a4.5 4.5 0 0 0 0 9H11v6a1 1 0 1 0 2 0V6h2v13a1 1 0 1 0 2 0V6h2a1 1 0 1 0 0-2H8.5Z" clip-rule="evenodd" />
    </svg>
    )
}

//An icon of a heading
const headingIcon = (size: number, color: string) => {
    let classString = "w-[" + size + "px] h-[" + size + "px] text-" + color;
    return (
        <svg className={classString} width={size} height={size} aria-hidden="true" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M3 5h18M3 9h18M3 13h18M3 17h18" />
        </svg>
    )
}

//An icon of a bullet list, with bullets
const bulletListIcon = (size: number, color: string) => {
    let classString = "w-[" + size + "px] h-[" + size + "px] text-" + color;

    return (<svg className={classString} width={size} height={size} aria-hidden="true" fill="none" viewBox="0 0 24 24">
        <path stroke="currentColor" strokeLinecap="round" strokeWidth="2" d="M9 8h10M9 12h10M9 16h10M4.99 8H5m-.02 4h.01m0 4H5" />
    </svg>
    )
}



//An icon of an ordered list, with numbers
const orderedListIcon = (size: number, color: string) => {
    let classString = "w-[" + size + "px] h-[" + size + "px] text-" + color;
    return (
        <svg className={classString} width={size} height={size} aria-hidden="true" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6h8m-8 6h8m-8 6h8M4 16a2 2 0 1 1 3.321 1.5L4 20h5M4 5l2-1v6m-2 0h4" />
        </svg>

    )
}

const codeBlockIcon = (size: number, color: string) => {
    let classString = "w-[" + size + "px] h-[" + size + "px] text-" + color;
    return (
        <svg className={classString} width={size} height={size} aria-hidden="true" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m8 8-4 4 4 4m8 0 4-4-4-4m-2-3-4 14" />
        </svg>

    )
}

const tableIcon = (size: number, color: string) => {
    let classString = "w-[" + size + "px] h-[" + size + "px] text-" + color;
    return (
        <svg className={classString} width={size} height={size} aria-hidden="true" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" strokeWidth="2" d="M3 11h18M3 15h18M8 10.792V19m4-8.208V19m4-8.208V19M4 19h16a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1Z" />
        </svg>

    )
}

const blockQuoteIcon = (size: number, color: string) => {
    let classString = "w-[" + size + "px] h-[" + size + "px] text-" + color;
    return (
        <svg className={classString} width={size} height={size} aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
            <path fill-rule="evenodd" d="M6 6a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h3a3 3 0 0 1-3 3H5a1 1 0 1 0 0 2h1a5 5 0 0 0 5-5V8a2 2 0 0 0-2-2H6Zm9 0a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h3a3 3 0 0 1-3 3h-1a1 1 0 1 0 0 2h1a5 5 0 0 0 5-5V8a2 2 0 0 0-2-2h-3Z" clip-rule="evenodd" />
        </svg>

    )
}

const horizontalRuleIcon = (size: number, color: string) => {
    let classString = "w-[" + size + "px] h-[" + size + "px] text-" + color;
    return (
        <svg className={classString} width={size} height={size} aria-hidden="true" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" strokeLinecap="round" strokeWidth="2" d="M5 12h14" />
            <path stroke="currentColor" strokeLinecap="round" d="M6 9.5h12m-12-2h12m-12-2h12m-12 13h12m-12-2h12m-12-2h12" />
        </svg>

    )
}

//An icon of an image, with a picture
const imageIcon = (size: number, color: string) => {
    let classString = "w-[" + size + "px] h-[" + size + "px] text-" + color;
    return (
        <svg className={classString} width={size} height={size} aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
            <path fill-rule="evenodd" d="M13 10a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2H14a1 1 0 0 1-1-1Z" clip-rule="evenodd" />
            <path fill-rule="evenodd" d="M2 6a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v12c0 .556-.227 1.06-.593 1.422A.999.999 0 0 1 20.5 20H4a2.002 2.002 0 0 1-2-2V6Zm6.892 12 3.833-5.356-3.99-4.322a1 1 0 0 0-1.549.097L4 12.879V6h16v9.95l-3.257-3.619a1 1 0 0 0-1.557.088L11.2 18H8.892Z" clip-rule="evenodd" />
        </svg>

    )
}

export default {
    decrementIcon,
    incrementIcon,
    fontSizeIcon,
    fontFamilyIcon,
    penIcon,
    undoIcon,
    redoIcon,
    alignmentLeftIcon,
    alignmentCenterIcon,
    alignmentRightIcon,
    alignmentJustifyIcon,

    boldIcon,
    italicIcon,
    underlineIcon,
    textColorIcon,
    strikeIcon,
    highlightColorIcon,
    linkIcon,
    unlinkIcon,
    codeIcon,
    footnoteIcon,
    superscriptIcon,
    subscriptIcon,
    clearAllIcon,
    clearHighlightIcon,

    paragraphIcon,
    headingIcon,
    imageIcon,
    bulletListIcon,
    orderedListIcon,
    codeBlockIcon,
    tableIcon,
    blockQuoteIcon,
    horizontalRuleIcon
};
