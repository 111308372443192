//React

//UI

//Services

//Logics
import createGUID from "logic/utility/createGUID";

//Classes
import { Mcq } from "classes/synapp/mcq/Mcq";
import { McqAnswer } from "classes/synapp/mcq/McqAnswer";
import { McqCodeQuestionSchema } from "classes/models/response/McqCodeQuestionSchema";

//Components

const parseAIResponseSchemaToMcqs = (inputString: string) => {

    let mcqs = JSON.parse(inputString);
    let newMcqs = [] as Mcq[];

    for (let i = 0; i < mcqs.questions.length; i++) {
        let mcq = mcqs.questions[i] as McqCodeQuestionSchema;
        let newMcq = new Mcq();

        let contentObject = {
            "type": "doc",
            "content": [
                {
                    "type": "heading",
                    "attrs": {
                        "id": createGUID(10),
                        "level": 3
                    },
                    "content": [
                        {
                            "type": "text",
                            "text": mcq.contentDocument.questionText
                        }
                    ]
                }
            ]

        };

        if (mcq.contentDocument.sourceCode !== "") {
            let sourceCodeObject = {
                "type": "codeBlock",
                "attrs": {
                    "id": createGUID(10),
                    "level": 3
                },
                "content": [
                    {
                        "type": "text",
                        "text": mcq.contentDocument.sourceCode
                    }
                ]
            }
            contentObject.content.push(sourceCodeObject);
        }

        newMcq.contentDocument = JSON.stringify(contentObject);
        newMcq.difficulty = mcq.difficulty;
        newMcq.answers = [];
        for (let j = 0; j < mcq.answers.length; j++) {
            let answer = mcq.answers[j];
            let newAnswer = new McqAnswer();
            newAnswer.answerText = answer.answerText;
            newAnswer.isCorrect = answer.isCorrect;
            newAnswer.explanation = answer.explanation;
            newMcq.answers.push(newAnswer);
        }
        newMcqs.push(newMcq);
    }

   
    return newMcqs;
}

export default { parseAIResponseSchemaToMcqs };