import { useCallback } from 'react';
import { useState } from 'react';
import { Editor } from '@tiptap/react';
import { ExtensionType } from 'classes/enums/ExtensionType';
import tiptapControlIcons from 'view_components/icons/tiptapControlIcons';

type Props = {
    editor: Editor;
    bubble: boolean;
}

const LinkPanel = () => {
    return (
        <div className="flex-auto">
            <input
                type="text"
                placeholder="Paste the link..."
                className="bg-gray-200 p-2 rounded m-2"
            />
            <div
                className="bg bg-blue-300 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-2"
            >
                Apply
            </div>
        </div>
    )

}

const TiptapPanelMarks = (props: Props) => {
    let editor = props.editor;
    const [showTextColorMenu, setShowTextColorMenu] = useState(false);
    const defaultSize = 10;
    const defaultClass = "bg select-none bg-col-t1 hover:bg-col-p1 text-white rounded size-" + defaultSize;
    const defaultCol = "col-p2";

    const setLink = useCallback(() => {
        if (!editor) {
            return
        }
        const previousUrl = editor.getAttributes('link').href
        const url = window.prompt('URL', previousUrl)

        // cancelled
        if (url === null) {
            return
        }

        // empty
        if (url === '') {
            editor.chain().focus().extendMarkRange('link').unsetLink()
                .run()

            return
        }

        // update link
        editor.chain().focus().extendMarkRange('link').setLink({ href: url })
            .run()
    }, [editor])

    if (!editor) {
        return null
    }

    // const returnButtonClassName = (isActive: Boolean) => {
    //     return "bg " + (isActive ? "bg-col-p2" : "bg-col-s2") + " hover:bg-col-s1 text-white font-bold py-2 px-4 rounded m-2";
    // }

    const checkIfDisabled = (type: string) => {
        switch (type) {
            case 'unlink':
                return !editor.isActive('link')
            default:
                return false;
        }
    }

    const getDefaultClass = (type: string = "") => {
        let isDisabled = checkIfDisabled(type);
        if (isDisabled) {
            let defaultClass = "bg select-none bg-white text-gray-400 cursor-not-allowed pointer-events-none rounded size-" + defaultSize;
            return defaultClass;
        } else {
            let attributeActive = editor.isActive(type);
            if (type === 'alignment') {
                attributeActive = editor.isActive({ textAlign: 'left' }) || editor.isActive({ textAlign: 'center' }) || editor.isActive({ textAlign: 'right' }) || editor.isActive({ textAlign: 'justify' });
            }
            let defaultClass = "bg select-none " + (attributeActive ? "bg-col-p1" : "bg-col-t1") + " hover:bg-col-p1 text-white cursor-pointer rounded size-" + defaultSize;
            return defaultClass;
        }
    }

    const returnBubbleClassName = () => {
        let bubbleClassName = "flex-auto";
        if (props.bubble) {
            //Place divs in a panel with a white background and a border
            bubbleClassName = "flex items-center gap-2 bg-col-t1 border border-gray-300 p-2 rounded shadow-lg";
        }
        return bubbleClassName;
    }

    const isValidTarget = () => {
        let isValid = true;
        if (editor.isActive('codeBlock') || editor.isActive('image') || editor.isActive(ExtensionType.Checkpoint) || editor.isActive(ExtensionType.CourseTextChallenge) || editor.isActive(ExtensionType.CodeExerciseChallenge)) {
            isValid = false;
        }
        return isValid;
    }

    return (
        <>
            {isValidTarget() && <div className={returnBubbleClassName()}>

                {/* ================================= Marks */}
                <div
                    onClick={() => editor.chain().focus().toggleBold().run()}
                    className={getDefaultClass('bold')}
                >
                    {tiptapControlIcons.boldIcon(defaultSize * 4, defaultCol)}
                </div>
                <div
                    onClick={() => editor.chain().focus().toggleItalic().run()}
                    className={getDefaultClass('italic')}

                >
                   {tiptapControlIcons.italicIcon(defaultSize * 4, defaultCol)}
                </div>
                <div
                    onClick={() => editor.chain().focus().toggleStrike().run()}
                    className={getDefaultClass('strike')}
                >
                   {tiptapControlIcons.strikeIcon(defaultSize * 4, defaultCol)}
                </div>
                <div
                    onClick={() => editor.chain().focus().toggleCode().run()}
                    className={getDefaultClass('code')}
                >
                    {tiptapControlIcons.codeIcon(defaultSize * 4, defaultCol)}
                </div>

                {/* ================================= Highlight */}
                <div
                    onClick={() => editor.chain().focus().unsetHighlight().run()}
                    className={getDefaultClass('highlight')}
                >
                    {tiptapControlIcons.clearHighlightIcon(defaultSize * 4, defaultCol)}
                </div>
                <div
                    onClick={() => editor.chain().focus().toggleHighlight({ color: 'yellow' }).run()}
                    //className={returnButtonClassName(editor.isActive('highlight', { color: '#yellow' }))}
                    className={getDefaultClass('highlight')}
                >
                    {tiptapControlIcons.highlightColorIcon(defaultSize * 4, defaultCol)}
                </div>
                <div
                    onClick={() => editor.chain().focus().unsetAllMarks().run()}
                    className={defaultClass}
                >
                    {tiptapControlIcons.clearAllIcon(defaultSize * 4, defaultCol)}
                </div>

                {/* <input
                    type="color"
                    //onInput={event => editor.chain().focus().setColor(event.target.value).run()}
                    onChange={event => editor.chain().focus().setColor(event.target.value).run()}
                    value={editor.getAttributes('textStyle').color}
                    data-testid="setColor"
                /> */}
                <div className={defaultClass} onClick={() => setShowTextColorMenu(true)}>
                    {tiptapControlIcons.textColorIcon(defaultSize * 4, editor.getAttributes('textStyle').color)}
                    {showTextColorMenu &&
                        <div className="absolute z-10 bg-white border border-gray-300 p-2 rounded shadow-lg">
                            <input
                                type="color"
                                onChange={(e) => { editor.chain().focus().setColor(e.target.value).run(); setShowTextColorMenu(false) }}
                                value={editor.getAttributes('textStyle').color}
                                data-testid="setColor"
                            />
                        </div>
                    }
                </div>
                {/* ================================= Link */}
                <div
                    onClick={setLink}
                    className={getDefaultClass('link')}
                >
                    {tiptapControlIcons.linkIcon(defaultSize * 4, defaultCol)}
                </div>
                <div
                    onClick={() => editor.chain().focus().unsetLink().run()}
                    className={getDefaultClass('unlink')}
                >
                    {tiptapControlIcons.unlinkIcon(defaultSize * 4, defaultCol)}
                </div>

            </div>}
        </>
    )
}
export default TiptapPanelMarks;  