//React
import { useEffect, useState } from 'react';

//UI

//Services
import { useAtom } from 'jotai';
import { loadedMcqs, courseAtom, courseProgressAtom } from 'atom';
import crudService from 'services/crudService';
import errorService from 'services/errorService';
import { toast } from 'react-toastify';

//Logics

//Classes
import { CheckpointTypes } from "classes/enums/CheckpointTypes";
import { PageCheckpointProgress } from "classes/courseprogress/PageCheckpointProgress";
import { FilterModel, PropertyFilter } from 'classes/models/request/FilterModel';
import { Class } from 'classes/enums/Class';
import { CourseChallenge } from 'classes/course/CourseChallenge';
import { CourseChallengeType } from 'classes/enums/CourseChallengeType';

//Components
import McqChallengeSystem from "views/courseview/challenge/McqChallengeSystem";

type Props = {
    courseChallenge: CourseChallenge;
    elementId: string;
}

const CheckpointMcqElement = (props: Props) => {
    const { elementId, courseChallenge } = props;

    const [courseProgress, setCourseProgress] = useAtom(courseProgressAtom);
    const [nodeCourse] = useAtom(courseAtom);
    const [mcqs, setMcqs] = useAtom(loadedMcqs);
    const [mcqStatus, setMcqStatus] = useState("checking");

    let categoryIds = courseChallenge.checkpoints.find((x) => x.elementId === elementId)?.categoryIds || [];
    let parentCategoryId = categoryIds.length > 0 ? categoryIds[categoryIds.length - 1] : "None";

    const checkpointIsComplete = (type: string) => {
        let challengeProgress = courseProgress.challengeProgresses.find((x) => x.challengeId === courseChallenge.id);
        if (challengeProgress && challengeProgress.checkpointProgresses.find((x) => x.elementId === elementId && x.type === type)) {
            return true;
        };
        return false;
    }

    const completeCheckpoint = async (pointsAwarded: number) => {
        let nodePageCheckpointProgress = new PageCheckpointProgress();
        nodePageCheckpointProgress.elementId = elementId;
        nodePageCheckpointProgress.type = CheckpointTypes.Mcq;
        nodePageCheckpointProgress.pointsAwarded = pointsAwarded;
        nodePageCheckpointProgress.dateCompleted = Date.now();
        nodePageCheckpointProgress.isCompleted = true;

        let updatedCourseProgress = { ...courseProgress };
        let challengeProgress = updatedCourseProgress.challengeProgresses.find((x) => x.challengeId === courseChallenge.id);

        if (!challengeProgress) {
            console.log(elementId)
            console.error("Challenge progress not found for course text mcq checkpoint");
            console.log(updatedCourseProgress);
            return;
        }

        challengeProgress.checkpointProgresses.push(nodePageCheckpointProgress);

        //If all checkpoints are complete, mark the challenge as complete
        let thisChallenge = nodeCourse.courseChallenges.find((courseChallenge) => courseChallenge.id === courseChallenge.id);
        if (thisChallenge && thisChallenge.checkpoints.length === challengeProgress.checkpointProgresses.length) {
            challengeProgress.isComplete = true;
            challengeProgress.dateCompleted = Date.now();
        }

        crudService.update(Class.courseProgress, updatedCourseProgress).then((response) => {
            if (response.success) {
                updatedCourseProgress.visibilityUpdated = true;
                setCourseProgress(updatedCourseProgress);
                toast.success("Checkpoint completed successfully!");
            }
            else {
                toast.error("Error updating checkpoint!");
            }
        });

    }

    // const getMcqs = async () => {

    //     let filter = new FilterModel([new PropertyFilter("CategoryIds", parentCategoryId)]);
    //     let response = await crudService.get(Class.mcq, filter);
    //     if (response.success) {
    //         //TODO: Add rather then replace, and then remove duplicates
    //         //TODO: if loadedMCqs are still less than 3 trigger error
    //         let newMcqs = mcqs.concat(response.payload);
    //         setMcqs(newMcqs);
    //         setMcqStatus("ready");
    //     } else {
    //         errorService.handleError(response);
    //     }
    // }

    // useEffect(() => {
    //     //Check if Mcqs are in memory with the required categoryIds       

    //     //TODO: throw error if parentCategoryIds is empty
    //     let numberOfMcqs = mcqs.filter((mcq) => mcq.categoryIds.includes(parentCategoryId)).length;
    //     if (numberOfMcqs < 3) {
    //         //Getting mcqs
    //         getMcqs();
    //     } else {
    //         setMcqStatus("ready");
    //     }
    // }, [elementId]);

    return (
        <div className="border border-col-p2 border-4 m-2 p-2 flex items-center justify-center">
            {!checkpointIsComplete(CheckpointTypes.Mcq) &&
                // mcqStatus === "ready" &&
                <McqChallengeSystem
                    categoryIds={categoryIds}
                    completeCheckpoint={completeCheckpoint}
                    parentType={CourseChallengeType.TextChallenge}
                    parentId={courseChallenge.id}
                />}

            {checkpointIsComplete(CheckpointTypes.Mcq) &&
                
                    <div className="text-lg md:text-2xl font-black mx-2 md:mx-4">Checkpoint Complete!</div>
                }
            {/* <div className="checkpoint">
                     <p>You have already completed this MCQ checkpoint.</p>
                 </div>
                } */}
        </div>
    )

}

export default CheckpointMcqElement;


