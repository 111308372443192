import { Editor } from '@tiptap/react';
import { ExtensionType } from 'classes/enums/ExtensionType';
import { CheckpointTypes } from 'classes/enums/CheckpointTypes';
import createGUID from 'logic/utility/createGUID';
import {useAtom} from 'jotai';
import {baseCategoryIdsAtom} from 'atom';

//TODO: consider creating the challenge objects here

type Props = {
    editor: Editor;
    disallowedNodes: string[]; 
}

const TiptapPanelSynappTypes = (props: Props) => {
    const { editor, disallowedNodes } = props;

    const [baseCategoryIds] = useAtom(baseCategoryIdsAtom);

    if (!editor) {
        return null
    }

    const returnButtonClassName = (isActive: Boolean) => {
        return "bg " + (isActive ? "bg-col-p2" : "bg-col-s2") + " hover:bg-col-s1 text-white font-bold py-2 px-4 rounded m-2";
    }

    return (

        <div className="flex-auto">

            {/* ================================= Types */}
            <button
                onClick={() =>
                    editor.chain().focus().insertContent({
                        type: ExtensionType.Checkpoint,
                        attrs: {
                            elementId: createGUID(10),
                            categoryIds: baseCategoryIds,
                        },
                    }).run()}
                className={returnButtonClassName(editor.isActive(ExtensionType.Checkpoint))}
            >
                Checkpoint
            </button>


            <button
                onClick={() =>
                    editor.chain().focus().insertContent({
                        type: ExtensionType.CourseText,
                        attrs: {
                            elementId: createGUID(10),
                        },
                    }).run()}
                className={returnButtonClassName(editor.isActive(ExtensionType.CourseText))}
            >
                Text View
            </button>

            <button
                onClick={() =>
                    editor.chain().focus().insertContent({
                        type: ExtensionType.CourseTextChallenge,
                        attrs: {
                            elementId: createGUID(10),
                            courseContentId: "None",

                            
                        },
                    }).run()}
                className={returnButtonClassName(editor.isActive(ExtensionType.CourseTextChallenge))}
            >
                Text Challenge
            </button>

            <button
                onClick={() =>
                    editor.chain().focus().insertContent({
                        type: ExtensionType.CodeExerciseChallenge,
                        attrs: {
                            elementId: createGUID(10),
                            categoryIds: baseCategoryIds,                            
                        },
                    }).run()}
                className={returnButtonClassName(editor.isActive(ExtensionType.CodeExerciseChallenge))}
            >
                Code Exercise Challenge
            </button>

        </div>

    )
}
export default TiptapPanelSynappTypes;  